export default [
  'costPerClick',
  'costPerConversion',
  'costPerUniqueLandingPageView',
  'costPerThousandImpressions',
  'costPerLead',
  'costPerThousandReach',
  'Cost Per Click ($)',
  'Cost Per Conversion ($)'
]
