<template>
  <div>
    <div>{{formatForType(current, formatter)}}</div>
    <div v-if="showCompare && typeof current === 'number'">
      <div class="text-muted" v-p-tooltip.top="'Previous period vs current period'">
        {{formatForType(previous, formatter, true)}}
        <div v-if="current === 0 && previous !== 0" class="text-muted">&infin;</div>
        <div v-else :class="variant">
          {{output}}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import positiveNegativeMetrics from 'core-ui/assets/js/constants/positiveNegativeMetrics'
import { computed, watchEffect, ref } from 'vue'
const props = defineProps(['current', 'previous', 'type', 'formatter', 'forceShow'])

const nuxtApp = useNuxtApp()

const showCompare = computed(() => props.forceShow || nuxtApp.$store.getters.timeRangeCompare)
const formatForType = (val, formatter, showParens) => {
  let retVal = ''
  if (typeof formatter === 'function') {
    retVal = formatter(val)
  } else {
    retVal = val
  }
  if (showParens && ((val && retVal) || val === 0)) {
    retVal = `[${retVal}]`
  }
  return retVal
}

const variant = ref('')
const output = ref('')
watchEffect(() => {
  const diff = props.previous !== 0 ? Math.round(((props.current / props.previous) - 1) * 100) : 0
  const plus = diff >= 0 ? '+' : ''
  const adjustedDiff = positiveNegativeMetrics.includes(props.type) ? diff * -1 : diff
  output.value = isFinite(diff) ? `${plus}${diff}%` : '-'
  variant.value = !isFinite(diff) ? 'text-muted' : adjustedDiff > 0 ? 'text-success' : adjustedDiff < 0 ? 'text-danger' : 'text-muted'
})


</script>


<style scoped>

</style>
